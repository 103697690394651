import React from "react"
import {  graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import LittleFeetLogo from "../images/logo_littlefeet.jpg"
import { layoutStyles } from '../style/layoutStyles'
import { littleFeetStyles } from '../style/littleFeet'
import Helmet from "react-helmet"

export default ({ data }) => {
  const post = data.wpgraphql.port

  var featuredImage =[];
  var keywords = [];
  var advert =[];

 
  if (post.keywords.nodes != null)
  {
    post.keywords.nodes.forEach(node => { keywords.push(node.name);});
  }
  if (post.featuredImage != null)
  {
    featuredImage.push({"url":post.featuredImage.node.mediaItemUrl,"title":post.featuredImage.node.altText, "link": post.AllPostTypeFields.bannerlink});
  }

  return (
    <Layout>
       <SEO title={post.title} keywords={keywords} />
        <div css={layoutStyles} id="content">
          <div css={littleFeetStyles}>
        <div className="content col-1-1">
            <div className="masthead">
                <div className="masthead littlefeet col-1-1">
                <Image scr={featuredImage} Mode="port"/> 
                </div>
               
            </div>
          </div>
        <div id="article">
        <div className="grid">
            <div id="sectionPage" className="col-1-1">
            <div id="LFtitle" className="col-1-1"><div id='LittleFeetLogo'><img src={LittleFeetLogo} alt="Little feet logo" id="LFlogo" /><span>Littlefeet Environmental</span></div>
             </div>
              <div id="aboutus" className="col-1-1">
                <div className="intro" dangerouslySetInnerHTML={{ __html: post.excerpt }} />   
                <img alt='' src={encodeURI(post.AllPostTypeFields.contentImage.contentImage1.mediaItemUrl)}/>
              </div>
             
            </div> 
        </div>
        <div className="bg_grey" id="whatwedo">
        <div className="grid">
              <div  className="col-1-1">
                <div className="content" dangerouslySetInnerHTML={{ __html: post.content }} />
              </div>
        </div>
        </div>
        <div className="grid">
            <div id="beaches" className="col-1-1">
              <h2>Jersey beaches</h2>
              <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1TSncfndLUi55UGCcBoIcjayPqUrgwN3U" ></iframe>
        </div>
        </div>
        <div className="bg_grey">
        <div className="grid">
            <div id="register" className="col-1-1">
              <h2>Register a beach clean event</h2>
              <div id="Rform" >
              <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfabjTFjET1dNjjQCRHsKZpWJiodk4m47XwnIOQuVvFUgD8hg/viewform?embedded=true" >Loading…</iframe>
              </div>
              <div id="iCal">
              <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FLondon&amp;src=amVyc2V5YmVhY2hjbGVhbkBnbWFpbC5jb20&amp;color=%23039BE5&amp;showTitle=0&amp;mode=AGENDA&amp;hl=en_GB&amp;showTz=0&amp;showTabs=0&amp;showPrint=0&amp;showCalendars=1"></iframe>

              </div>
            
 
        </div>
        </div>
        <div className="grid" id="registerR">
        <div className="col-1-1">
        <p class="remark">Data inputted here will be shared between Littlefeet Environmental and Ports of Jersey for purposes of monitoring beach cleaning in Jersey. All beach cleaning activity is understood to be undertaken on your own accord and you are personally accepting any and all risks, unless you are part of an organised group with a local business, club, society, organisation or charity, where their guidance in these matters would apply. </p>
        </div>
        </div>
        </div>
        <div className="grid">
            <div id="response" className="col-1-1">
            <h2>Your beach clean experience</h2>
<div id="ExpText">
            <div dangerouslySetInnerHTML={{ __html: post.AllPostTypeFields.advert }} />
          
</div>
       <div id="Sform" >
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdNCMQla2D2dUvsZ91BLkoyy9wEXfi99DgTQSN_2NSn1q7sDg/viewform?embedded=true">Loading…</iframe>
        </div>
        <p class="remark">Data inputted here will be shared between Littlefeet Environmental and Ports of Jersey for purposes of monitoring beach cleaning in Jersey. All beach cleaning activity is understood to be undertaken on your own accord and you are personally accepting any and all risks, unless you are part of an organised group with a local business, club, society, organisation or charity, where their guidance in these matters would apply. </p>
        </div>
        </div>
        <div className="grid">
            <div id="jerseybeachclean" className="col-1-1">
              <br/>
            <p>Ports of Jersey has an important role to play in promoting a positive environmental culture and protecting the ecosystem. We look at ways to work with our community and local charities to protect our coastal environment, keeping our historic harbours and beaches clean and safe. 

</p><p>Through our partnership with Littlefeet, we’re delighted to be able to promote cleaning of the island’s beaches and coastal areas of interest. We’d encourage everyone to get involved and help us keep Jersey beaches looking beautiful for everyone to enjoy. 
</p>
            <h2>#jerseybeachclean</h2>
            <Helmet><script defer src="https://widget.taggbox.com/embed.min.js" type="text/javascript"></script></Helmet>
        <div className="taggbox-container"><div className="taggbox-socialwall" data-wall-id="43905" view-url="https://app.taggbox.com/widget/e/43905">  </div></div>
        </div>
        </div>

        <div className="bg_grey" id="contact">
        <div className="grid">
            <div className="col-1-1">
              <h2>Contact us</h2>
           <ul>
             <li>Tel: 07700 344491</li>
           <li>Email: <a href="mailto:courtneyhuisman@hotmail.com">courtneyhuisman@hotmail.com</a></li>
           <li>Facebook:<a href="https://www.facebook.com/littlefeetenvironmental">@littlefeetenvironmental</a></li>
           </ul>
              
             
              </div>
        </div>
        </div>
      </div>
      </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
query GET_LITTLEFEET($id: ID!) {
    wpgraphql {
      port(id: $id) {
        title
        content(format: RENDERED)
        slug
        excerpt
        keywords {
          nodes {
            name
          }
        }
        featuredImage {
          node
          {
          mediaItemUrl
          altText
          }
        }
        children {
          nodes {
            uri
          }
        }
        AllPostTypeFields {
          advert
          bannerlink
          contentImage{
            contentImage1 {
              mediaItemUrl
              title
            }
            contentImage2 {
              mediaItemUrl
              title
            }
            contentImage3 {
              mediaItemUrl
              title
            }
            contentImage4 {
              mediaItemUrl
              title
            }
            contentImage5 {
              mediaItemUrl
              title
            }
            contentImage6 {
              mediaItemUrl
              title
            }
            contentImage7{
              mediaItemUrl
              title
            }
            contentImage8 {
              mediaItemUrl
              title
            }
            contentImage9 {
              mediaItemUrl
              title
            }
            contentImage10 {
              mediaItemUrl
              title
            }

          }
          relatedLinks {
            relatedLink1 {
              title
              url
              target
            }
            relatedLink2 {
              title
              url
              target
            }
            relatedLink3 {
              title
              url
              target
            }
            relatedLink4 {
              title
              url
              target
            }
            relatedLink5 {
              title
              url
              target
            }
            relatedLink6 {
              title
              url
              target
            }
            relatedLink7 {
              title
              url
              target
            }
            relatedLink8 {
              title
              url
              target
            }
            relatedLink9 {
              title
              url
              target
            }
            relatedLink10 {
              title
              url
              target
            }
          }
          relatedSectionPages {
            relatedPage1 {
            title
            url
            target
          }
          relatedPage2 {
            title
            url
            target
          }
          relatedPage3 {
            title
            url
            target
          }
          relatedPage4 {
            title
            url
            target
          }
          relatedPage5 {
            title
            url
            target
          }
          relatedPage6 {
            title
            url
            target
          }
          relatedPage7 {
            title
            url
            target
          }
          relatedPage8 {
            title
            url
            target
          }
          relatedPage9 {
            title
            url
            target
          }
          relatedPage10 {
            title
            url
            target
          }
        }
        relatedDocuments {
          relatedDocumentTitle1
          relatedDocumentTitle2
          relatedDocumentTitle3
          relatedDocumentTitle4
          relatedDocumentTitle5
          relatedDocumentTitle6
          relatedDocumentTitle7
          relatedDocumentTitle8
          relatedDocumentTitle9
          relatedDocumentTitle10
          relatedDocument1 {
            mediaItemUrl
            title
          }
          relatedDocument2 {
            mediaItemUrl
            title
          }
          relatedDocument3 {
            mediaItemUrl
            title
          }
          relatedDocument4 {
            mediaItemUrl
            title
          }
          relatedDocument5 {
            mediaItemUrl
            title
          }
          relatedDocument6 {
            mediaItemUrl
            title
          }
          relatedDocument7 {
            mediaItemUrl
            title
          }
          relatedDocument8 {
            mediaItemUrl
            title
          }
          relatedDocument9 {
            mediaItemUrl
            title
          }
          relatedDocument10 {
            mediaItemUrl
            title
          }
        }
      }
      }
    }
  }
  
`