import { css } from "@emotion/core"

export const littleFeetStyles = css`
h2,#article .intro p{color:	#1d1d1d;}
#response,#registerR{ p.remark{font-size:12px; text-align:left;line-height:1.5em;margin-top:1em;}}
#LFtitle{display:block;height:80px;margin-top:10px;}
#LittleFeetLogo{
  width:100%;
  display:inline;
  font-size:34px;
  margin-top:30px;
  height:80px;
  margin-bottom:15px;
  position:relative;
  padding-left:90px;

  img{height:80px;position:absolute;top:-10px;left:0;
  }
  @media (max-width: 820px) {
    font-size:28px;
    text-align:left;
    width:100%;
    display:block;
  
  }
  @media (max-width: 600px) {
  img{top:0;}
  }

}
.masthead.littlefeet.col-1-1{
  height:450px;
  overflow:hidden;
  border:0;
}
.masthead.littlefeet img{
  width:100%;
  height:auto;
  
}
#article .intro p{
  text-align:center;
  font-size:28px;
  line-height:45px;
}
#article{
  text-align:center;
}
#article h2{
  font-size:34px;
  font-weight:normal;
}
#article p{
  font-size:18px;
  line-height:32px;
}
.bg_grey
{
  width:100%;
  background-color:#eee;
  padding-bottom:45px;
  margin-top:45px;
  @media (max-width: 600px) {
  margin: 0 -10px;
    width: calc(100% + 20px);
    padding: 0 15px;
  }
}
#beaches iframe{
  width:100%;
  height:450px;
  border:0;
}
#register 
{
height:1120px;
overflow:none;

}
#register #Rform
{
  width:calc(70% - 20px);
  height:1200px;
  overflow:hidden;
  position:relative;
  display:inline-block;
}
#register #Rform iframe{
  position:absolute;
  left:-20px;
  width:calc(100% + 20px);
  height:1205px;
  border:0;
}
#register #iCal{
  width:calc(30% + 20px);
  height:1200px;
  overflow:hidden;
  position:relative;
  display:inline-block;
  padding-top:20px;
}
#register #iCal iframe{
  width:100%;
  height:775px;
  border:0;
}
#Sform iframe{
  width:100%;
  border:0;
  height:1600px;
  overflow:hidden;
}
#ExpText,#Sform{
  width:50%;
  display:inline-block;
  vertical-align:top;

  iframe{
    width:480px;
  }
}
#Sform{
  height:1500px;
  overflow:hidden;
}
#ExpText h3{
  font-size:24px;
  font-weight:normal;
}
#ExpText iframe{
  width:340px;
  height:220px;
  border:0;
  margin:0 auto;
  overflow:hidden;
}
#jerseybeachclean{
  margin-top:20px;
  border-top:1px solid #ccc;
}
#contact{
  margin-bottom:-45px;
  box-sizing:border-box;
  ul{
    list-style:none;
  li{
    list-style:none;
    font-size:22px;
  line-height:2em;
  display:inline;
  }
}
}
.taggbox-container{ width:100%;height:100%;overflow: auto;}
@media (max-width: 900px) {
  #register 
{
height:1170px;
overflow:none;

}
#register #Rform
{
  width:calc(70% - 20px);
  height:1250px;
  overflow:hidden;
  position:relative;
  display:inline-block;
}
#register #Rform iframe{
  position:absolute;
  left:-20px;
  width:calc(100% + 20px);
  height:1255px;
  border:0;
}
}
@media (max-width: 768px) {
  #ExpText h3{
    font-size:18px;
  }
  #article h2,#article .intro p{
    font-size:24px;
    line-height:1.5em;
  }
  #beaches iframe{
    width:100%;
    height:250px;
    position:relative;
  }
  #register{
    padding:0;
    height:1300px;
    overflow:hidden

  }

  #register #Rform,#register #iCal,#ExpText,#Sform,#Sform iframe
  {
    width:100%;
  }
#register #iCal
  {
    height:320px;
    padding:0;

  }
  #register #iCal iframe{
    height:300px;
    position:relative;
  }
  #register #Rform
  {
    height:1100px;
  overflow:hidden;}
  #Sform{
    height:1500px;
    overflow:hidden;
  }
  #contact{
    margin-bottom:0;
    ul{
    li{
      font-size:18px;
      display:block;
    }
  }
  }
 
}
@media (max-width: 600px) {
  #Sform{
    height:1580px;
    iframe{
      height:1580px;
    }
  }
  #register{
    height:1580px
  }
  #register #Rform
  {
    height:1280px;
  overflow:hidden;}
  #register #Rform iframe{
    width:100%;
    position:relative;
    left:0;
    height:1300px;
    overflow:hidden;
  }

}
`
